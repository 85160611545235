<template lang="pug">
.item-date
  .item-date__block(v-if="mode === 'calculation'")
    .item-date__title Дата публикации
    .item-date__date
      .date-time
        .date {{ dateTime.datePublic.date }}
        .time(v-if="dateTime.datePublic.time") {{ dateTime.datePublic.time }}

  .item-date__block(v-if="mode === 'calculation' || mode === 'filling'")
    .item-date__title Подача заявок до
    .item-date__date
      .date-time
        .date {{ dateTime.dateApplication.date }}
        .time(v-if="dateTime.dateApplication.time") {{ dateTime.dateApplication.time }}
      circle-number(:date-to="application?.dateApplication")

  .item-date__block(v-if="mode === 'filling' || mode === 'bargaining'")
    .item-date__title Дата торгов
    .item-date__date
      .date-time
        .date
          span {{ dateTime.dateAuction.date }}
          old-warning(v-if="!isCorrectDateAuction && !isDateAuctionCalculatedByFZ44" label="Время на площадке не указано")
        .time(v-if="dateTime.dateAuction.time && (isCorrectDateAuction || isDateAuctionCalculatedByFZ44)")
          span {{ dateTime.dateAuction.time }}
          old-warning(v-if="isDateAuctionCalculatedByFZ44" size="small")
            .warning Дата и время торгов были пересчитаны согласно правилам для закупок 44-ФЗ и типа "Электронный аукцион", а именно "Дата окончания подачи заявок" + 2 часа
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { getDateTimeTimezone } from "@/utils/formatter/dateFormatter";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useRoute } from "vue-router";
import { Tabs } from "@/router/tabs";

import CircleNumber from "@/components/ui/circle/CircleNumber.vue";
import OldWarning from "~/components/ui/tooltip/OldWarning.vue";

import type { PropType } from "vue";
import type { LotItemInterface } from "@/stores/search/LotItemInterface";

export default defineComponent({
  name: "ApplicationItemDates",
  components: {
    OldWarning,
    CircleNumber,
  },
  props: {
    application: {
      type: Object as PropType<LotItemInterface>,
      default: () => ({}),
    },
  },
  setup(props) {

    const route = useRoute();
    const { me } = useUserAccess();

    const mode = computed(() => {
      switch (route.name) {
        case Tabs.Applications.Main: return 'filling';
        case Tabs.Applications.Calculation: return 'calculation';
        case Tabs.Applications.Participation: return 'filling';
        case Tabs.Applications.Bargaining: return 'bargaining';
        default: return 'calculation';
      }
    })

    const dateTime = computed(() => {
      const datePublic = getDateTimeTimezone(props.application.datePublic, props.application.currentUserTimezone || me.value.timezone)
      const dateApplication = getDateTimeTimezone(props.application.dateApplication, props.application.currentUserTimezone || me.value.timezone)
      const dateAuction = getDateTimeTimezone(props.application.dateAuction, props.application.currentUserTimezone || me.value.timezone)

      return {
        datePublic, dateApplication, dateAuction,
      }
    })

    const isCorrectDateAuction = props.application.hasOwnProperty('isCorrectDateAuction') ? props.application.isCorrectDateAuction : true;
    const isDateAuctionCalculatedByFZ44 = props.application.hasOwnProperty('isDateAuctionCalculatedByFZ44') ? props.application.isDateAuctionCalculatedByFZ44 : false;

    return {
      mode,
      dateTime,
      isCorrectDateAuction,
      isDateAuctionCalculatedByFZ44,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/lots/lotItemDates";

.warning {
  max-width: 240px;
}
</style>
