<template lang="pug">
.app-status__selector
  ui-select(
    placeholder="Выберите статус"
    label="Статус заявки"
    :full-info="true"
    :teleported="false"
    :persistent="false"
    :disabled="disabled"
    :model-value="selectedStatus"
    :options="appStatuses"
    @change-select="setStatus($event.value, $event.oldValue)"
  )
  ui-icon.icon-favorite(
    v-if="!disabled && appStatuses.length"
    clickable
    :size="22"
    :class="selectedStatus?.id === AppStatusFavourite && '_active'"
    :icon="selectedStatus?.id === AppStatusFavourite ? UiIconNames.Icon_Star : UiIconNames.Icon_StarO"
    @click="setStatus(appStatuses.find(e => e.id === AppStatusFavourite), selectedStatus)"
  )
  ui-icon.icon-ban(
    v-if="!disabled && appStatuses.length"
    clickable
    :size="22"
    :class="selectedStatus?.id === AppStatusBlock && '_active'"
    :icon="UiIconNames.Icon_Block"
    @click="setStatus(appStatuses.find(e => e.id === AppStatusBlock), selectedStatus)"
  )
  icon-button(
    v-if="lot?.app?.isArchived"
    as-button
    button-type="secondary"
    tooltip-title="Извлечь из архива"
    :icon="UiIconNames.Icon_Refresh"
    @click="$emit('extractFromArchive')"
  )
</template>

<script lang="ts">
import { computed, defineComponent, } from "vue";
import { storeToRefs } from "pinia";
import { useAppModifier } from "~/components/pages/applications/list/useAppModifier";

import type { PropType } from "vue";
import type { StatusI } from "@/stores/manuals/ManualsInterface";
import type { AppItemInterface, LotItemInterface } from "@/stores/search/LotItemInterface";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiSelect from "@/components/ui/select/select/UiSelect.vue";
import IconButton from "@/components/ui/button/IconButton.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";
import UiIconNames from "@/components/ui/icon/UiIconNames";
import { AppStatusFavourite, AppStatusBlock, AppStatusWin } from "@/const/statuses";

export default defineComponent({
  name: "AppStatusSelector",
  components: {
    IconButton,
    UiIcon,
    UiSelect,
  },
  emits: [
    'showCreateContract',
    'extractFromArchive',
  ],
  props: {
    lot: {
      type: Object as PropType<LotItemInterface | AppItemInterface>,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const manualsStore = useManualsStore()
    const { appStatuses } = storeToRefs(manualsStore)

    const selectedStatus = computed(() => {
      if (props.lot?.app?.status) {
        return {
          label: props.lot?.app?.status?.title,
          ...props.lot?.app?.status,
        };
      }

      return null;
    })

    const {
      createNewApp,
      updateAppStatus,
    } = useAppModifier();

    function setStatus(status: StatusI, oldStatus: StatusI) {
      if (status) {
        if (status?.id === AppStatusWin) {
          context.emit('showCreateContract', { responsiblePerson: props.lot?.app?.responsiblePerson, cost: props.lot?.cost.original, lotId: props.lot?.lotId })
        }
        updateLot(status, oldStatus)
      }
    }

    function updateLot(status: StatusI, oldStatus: StatusI) {
      if (props.lot!.app) {
        updateAppStatus(props.lot, status, oldStatus)
          .catch(() => {})
      } else {
        createNewApp(props.lot, status)
          .catch(() => {})
      }
    }

    return {
      setStatus,
      appStatuses,
      selectedStatus,
      UiIconNames,
      AppStatusFavourite,
      AppStatusBlock,
    };
  },
});
</script>

<style scoped lang="scss">
.app-status__selector {
  display: flex;
  align-items: flex-end;
  gap: 6px;
  width: 260px;

  .icon-favorite {
    display: flex;
    height: 34px;
    color: gray;
    cursor: pointer;

    &._active {
      color: #ff9c41;
    }
  }

  .icon-ban {
    display: flex;
    height: 34px;
    color: gray;
    cursor: pointer;

    &._active {
      color: var(--main-red-color);
    }
  }
}
</style>
